import React, { useEffect, useState } from "react";
import "./App.css";
import axios from "axios";
import { DateTime } from "luxon";

const styles = () => {};

function App() {
  const [lotteNotes, setLotteNotes] = useState<
    { date: String; type: "maybeActivity" | "note"; note: String }[]
  >([]);
  const [vildeNotes, setVildeNotes] = useState<
    { date: String; type: "maybeActivity" | "note"; note: String }[]
  >([]);
  const [dateRange, setDateRange] = useState<DateTime[]>([]);
  const [referenceDate, setReferenceDate] = useState<DateTime>(
    DateTime.now().weekday === 6 || DateTime.now().weekday === 7
      ? DateTime.now().endOf("week").plus({ days: 1 })
      : DateTime.now()
  );

  useEffect(() => {
    const startOfWeekReferenceDate = referenceDate.startOf("week");
    const dates = [];
    for (let i = 0; i < 5; i++) {
      dates.push(
        DateTime.local(
          startOfWeekReferenceDate.year,
          startOfWeekReferenceDate.month,
          startOfWeekReferenceDate.day
        ).plus({ days: i })
      );
    }
    setDateRange(dates);

    // const tokens = axios
    //   .post("https://spireportalen.no/graphql?Authenticate", {
    //     operationName: "Authenticate",
    //     variables: {
    //       email: "",
    //       password: "",
    //       deviceId: null,
    //       legacy: false,
    //     },
    //     query:
    //       "mutation Authenticate($email: EmailAddress!, $password: Password!, $deviceId: DeviceId, $legacy: Boolean) {\n  me {\n    authenticateWithPassword(email: $email, password: $password, deviceId: $deviceId, legacy: $legacy) {\n      ...AuthenticationResult\n      __typename\n    }\n    __typename\n  }\n}\n\nfragment AuthenticationResult on AuthenticationResult {\n  status\n  __typename\n  ... on AuthenticationFailed {\n    status\n    errorDetails\n    errorTitle\n    __typename\n  }\n  ... on AuthenticationSucceeded {\n    accessToken\n    deviceId\n    __typename\n  }\n  ... on AuthenticationChallenged {\n    ...AuthChallenge\n    __typename\n  }\n}\n\nfragment AuthChallenge on AuthenticationChallenged {\n  loginId\n  deviceId\n  expiresAt\n  choices {\n    context {\n      ...UserContextFragment\n      __typename\n    }\n    hmac\n    requiresTwoFactor\n    __typename\n  }\n  person {\n    name {\n      fullName\n      __typename\n    }\n    profileImage {\n      url\n      __typename\n    }\n    __typename\n  }\n  __typename\n}\n\nfragment UserContextFragment on UserContext {\n  id\n  target {\n    __typename\n    ... on PersonContextTarget {\n      person {\n        name {\n          fullName\n          __typename\n        }\n        __typename\n      }\n      children {\n        name {\n          firstName\n          fullName\n          __typename\n        }\n        profileImage {\n          url\n          __typename\n        }\n        __typename\n      }\n      __typename\n    }\n    ... on InstitutionSet {\n      title\n      profileImage {\n        url\n        __typename\n      }\n      __typename\n    }\n  }\n  __typename\n}\n",
    //   })
    //   .then((res) => {
    //     debugger;
    //     return res.data.data.me.authenticateWithPassword.accessToken;
    //   });

    const lessonPlansLotte = axios.post(
      "https://spireportalen.no/graphql?LessonPlans",
      {
        operationName: "LessonPlans",
        variables: {
          groupIds: null,
          childIds: ["da09877d-c147-4a96-a47e-4e8b9f0b7b6c"],
          dateRange: {
            from: startOfWeekReferenceDate.toISODate(),
            to: startOfWeekReferenceDate.toISODate(),
          },
        },
        query:
          "query LessonPlans($groupIds: [GroupId!], $childIds: [ChildId!], $dateRange: ClosedLocalDateRange!) {\n  childDevelopment {\n    lessonPlans {\n      list(groupIds: $groupIds, childIds: $childIds, dateRange: $dateRange) {\n        lessonPlans {\n          ...LessonPlanInfo\n          __typename\n        }\n        __typename\n      }\n      __typename\n    }\n    __typename\n  }\n}\n\nfragment LessonPlanInfo on LessonPlan {\n  id\n  description\n  startDate\n  endDate\n  published\n  sections {\n    id\n    order\n    name\n    hiddenFromParents\n    items {\n      ... on LessonPlanActivity {\n        ...LessonPlanActivityItem\n        __typename\n      }\n      ... on LessonPlanNote {\n        ...LessonPlanNoteItem\n        __typename\n      }\n      __typename\n    }\n    __typename\n  }\n  behaviors {\n    behaviorId\n    __typename\n  }\n  __typename\n}\n\nfragment LessonPlanNoteItem on LessonPlanNote {\n  __typename\n  id\n  date\n  order\n  note\n}\n\nfragment LessonPlanActivityItem on LessonPlanActivity {\n  __typename\n  id\n  date\n  order\n  maybeActivity {\n    id\n    title\n    latest {\n      id\n      areas {\n        ...AreaWithParent\n        __typename\n      }\n      __typename\n    }\n    __typename\n  }\n}\n\nfragment AreaWithParent on Area {\n  ...Area\n  parent {\n    ...Area\n    __typename\n  }\n  __typename\n}\n\nfragment Area on Area {\n  title\n  description\n  abbr\n  color\n  id\n  lower\n  upper\n  framework {\n    id\n    title\n    abbr\n    __typename\n  }\n  statements {\n    id\n    body\n    __typename\n  }\n  __typename\n}\n",
      },
      {
        headers: {
          "X-Famly-Accesstoken": "b1e631ea-3439-4325-8325-9f61fd674ce1",
        },
      }
    );
    lessonPlansLotte.then((res) => {
      if (
        res.data.data.childDevelopment.lessonPlans.list.lessonPlans.length === 0
      ) {
        return;
      }

      setLotteNotes(
        res.data.data.childDevelopment.lessonPlans.list.lessonPlans[0].sections[0].items.map(
          (item: any) => {
            return {
              date: item.date,
              type: item.maybeActivity ? "maybeActivity" : "note",
              note: item.maybeActivity ? item.maybeActivity.title : item.note,
            };
          }
        )
      );
    });

    const lessonPlansVilde = axios.post(
      "https://spireportalen.no/graphql?LessonPlans",
      {
        operationName: "LessonPlans",
        variables: {
          groupIds: null,
          childIds: ["d4ec166c-7823-458c-be21-19739283aaa7"],
          dateRange: {
            from: startOfWeekReferenceDate.toISODate(),
            to: startOfWeekReferenceDate.toISODate(),
          },
        },
        query:
          "query LessonPlans($groupIds: [GroupId!], $childIds: [ChildId!], $dateRange: ClosedLocalDateRange!) {\n  childDevelopment {\n    lessonPlans {\n      list(groupIds: $groupIds, childIds: $childIds, dateRange: $dateRange) {\n        lessonPlans {\n          ...LessonPlanInfo\n          __typename\n        }\n        __typename\n      }\n      __typename\n    }\n    __typename\n  }\n}\n\nfragment LessonPlanInfo on LessonPlan {\n  id\n  description\n  startDate\n  endDate\n  published\n  sections {\n    id\n    order\n    name\n    hiddenFromParents\n    items {\n      ... on LessonPlanActivity {\n        ...LessonPlanActivityItem\n        __typename\n      }\n      ... on LessonPlanNote {\n        ...LessonPlanNoteItem\n        __typename\n      }\n      __typename\n    }\n    __typename\n  }\n  behaviors {\n    behaviorId\n    __typename\n  }\n  __typename\n}\n\nfragment LessonPlanNoteItem on LessonPlanNote {\n  __typename\n  id\n  date\n  order\n  note\n}\n\nfragment LessonPlanActivityItem on LessonPlanActivity {\n  __typename\n  id\n  date\n  order\n  maybeActivity {\n    id\n    title\n    latest {\n      id\n      areas {\n        ...AreaWithParent\n        __typename\n      }\n      __typename\n    }\n    __typename\n  }\n}\n\nfragment AreaWithParent on Area {\n  ...Area\n  parent {\n    ...Area\n    __typename\n  }\n  __typename\n}\n\nfragment Area on Area {\n  title\n  description\n  abbr\n  color\n  id\n  lower\n  upper\n  framework {\n    id\n    title\n    abbr\n    __typename\n  }\n  statements {\n    id\n    body\n    __typename\n  }\n  __typename\n}\n",
      },
      {
        headers: {
          "X-Famly-Accesstoken": "b1e631ea-3439-4325-8325-9f61fd674ce1",
        },
      }
    );
    lessonPlansVilde.then((res) => {
      if (
        res.data.data.childDevelopment.lessonPlans.list.lessonPlans.length === 0
      ) {
        return;
      }
      setVildeNotes(
        res.data.data.childDevelopment.lessonPlans.list.lessonPlans[0].sections[0].items.map(
          (item: any) => {
            return {
              date: item.date,
              type: item.maybeActivity ? "maybeActivity" : "note",
              note: item.maybeActivity ? item.maybeActivity.title : item.note,
            };
          }
        )
      );
    });
  }, [referenceDate]);

  return (
    <div
      className="App"
      style={{
        display: "grid",
        gridTemplateRows: "auto 1fr 1fr 3fr",
        gridTemplateColumns: "100%",
        height: "100vh",
        margin: 0,
      }}
    >
      <div className="onlyVisibleOnMobile">
        <input
          type="date"
          value={referenceDate.toISODate()!}
          onChange={(newValue) =>
            setReferenceDate(DateTime.fromISO(newValue.target.value))
          }
        ></input>
      </div>
      <div className="header">
        <div>
          <input
            type="date"
            value={referenceDate.toISODate()!}
            onChange={(newValue) =>
              setReferenceDate(DateTime.fromISO(newValue.target.value))
            }
          ></input>
        </div>
        {dateRange.map((date) => {
          return (
            <div>
              {date.toISODate()}
              <br />
              {date.toLocal().weekdayLong}
            </div>
          );
        })}
      </div>
      <div className="dayContent">
        <div>
          <img height="200px" width="200px" src="lotte.jpg" />
        </div>
        {dateRange.map((date) => {
          return (
            <div>
              <div className="onlyVisibleOnMobile">
                <b>
                  {date.toISODate()} - {date.toLocal().weekdayLong}
                </b>
              </div>
              {lotteNotes
                .filter((note) => note.date === date.toISODate())
                .map((note) => {
                  return note.type === "maybeActivity" ? (
                    <div style={{ color: "red", fontWeight: "bold" }}>
                      <p>{note.note}</p>
                    </div>
                  ) : (
                    <div>
                      <p>{note.note}</p>
                    </div>
                  );
                })}
            </div>
          );
        })}
      </div>
      <div className="dayContent">
        <div>
          <img height="200px" width="200px" src="vilde.jpg" />
        </div>
        {dateRange.map((date) => {
          return (
            <div>
              <div className="onlyVisibleOnMobile">
                <b>
                  {date.toISODate()} - {date.toLocal().weekdayLong}
                </b>
              </div>
              {vildeNotes
                .filter((note) => note.date === date.toISODate())
                .map((note) => {
                  return (
                    <div>
                      <p>{note.note}</p>
                    </div>
                  );
                })}
            </div>
          );
        })}
      </div>
      <iframe
        src="https://calendar.google.com/calendar/embed?height=600&wkst=2&bgcolor=%23ffffff&ctz=Europe%2FOslo&showTitle=0&showNav=1&showPrint=0&showTabs=1&showCalendars=0&showTz=0&src=dGVyamVzbmFyYnlAZ21haWwuY29t&src=YWRkcmVzc2Jvb2sjY29udGFjdHNAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&src=bW8wb2pycmoycWllYzBhbW5mc2hsaWJiajdlZWYzcDVAaW1wb3J0LmNhbGVuZGFyLmdvb2dsZS5jb20&src=ZW4ubm9yd2VnaWFuI2hvbGlkYXlAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&color=%237986CB&color=%2333B679&color=%23EF6C00&color=%230B8043"
        style={{ height: "100%", width: "100%" }}
      ></iframe>
    </div>
  );
}

export default App;
